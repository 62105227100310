exports.shouldUpdateScroll = ({
    routerProps: { location }
}) => {
    const { hash } = location
    // list of routes for the scroll-to-top-hook
    if (!!hash) {
        const strippedHash = hash.substring(1)
        document.getElementById(strippedHash).scrollIntoView()
    } else {
        window.scrollTo(0, 0)
    }

    return false
}